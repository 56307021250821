<script setup>
const config = useRuntimeConfig()
const environment = config.public?.VITE_APP_ENV
</script>

<template>
  <environment-banner class="fixed inset-x-0 top-0 z-50" />
  <nuxt-loading-indicator />

  <nav-bar :class="environment === 'local' || environment === 'staging' ? 'top-10' : ''" />

  <main class="px-6 py-6 md:px-20" :class="environment === 'local' || environment === 'staging' ? 'mt-[100px]' : 'mt-[72px]'">
    <slot />
  </main>
</template>
